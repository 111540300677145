import styled from "@emotion/styled";
import theme from "@src/styles/base";
import { mediaQueriesMax } from "@src/styles/theme";

export const MarqueeImage = styled.img`
  margin-inline: 40px;
`;

export const StatContainer = styled.div`
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Body1 = styled.p`
  font-family: "HafferXH";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: -0.1px;
  margin-bottom: 0;
`;

export const Display1 = styled.h1`
  font-family: "HafferXH";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.24px;
  text-wrap: balance;
  margin-bottom: 0;
`;

export const Button = styled.button`
  color: #fff;
  height: 60px;
  padding: 0px ${theme.spacing[24]};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing[6]};
  border-radius: 100px;
  background: ${theme.colors.contentAccent};
  font-family: "HafferXH";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  cursor: pointer;
  border: none;
  &:hover {
    background: ${theme.colors.grape70};
  }
  &:active {
    background: ${theme.colors.grape80};
`;

export const FeatureCard = styled.div`
  border: solid 1px ${theme.colors.bordersDecorative};
  background-color: ${theme.colors.backgroundSecondary};
  padding: ${theme.spacing[30]};
  border-radius: 24px;
  text-align: center;
  img {
    max-height: 350px;
  }
  ${mediaQueriesMax[3]} {
    min-width: 562px;
  }
  ${mediaQueriesMax[2]} {
    min-width: 462px;
  }
  ${mediaQueriesMax[1]} {
    min-width: 85vw;
  }
`;

export const OverflowCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-block: ${theme.spacing[18]};
  padding-inline: ${theme.spacing[18]};
  gap: ${theme.spacing[18]};
  scrollbar-width: none;
  ${mediaQueriesMax[3]} {
    padding-inline: calc(50% - 570px);
  }
  ${mediaQueriesMax[2]} {
    padding-inline: calc(50% - 470px);
  }
  ${mediaQueriesMax[1]} {
    padding-inline: calc(50% - 360px);
  }
  ${mediaQueriesMax[0]} {
    padding-inline: ${theme.spacing[18]};
  }
`;

export const Headline1 = styled.h2`
  font-family: "HafferXH";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  letter-spacing: -0.36px;
  margin-block: 0;
`;

export const Body2 = styled.p`
  font-family: "HafferXH";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

export const Body2Bold = styled(Body2)`
  font-weight: 700;
`;

export const Body2SemiBold = styled(Body2)`
  font-weight: 600;
`;

export const Headline2 = styled.h2`
  font-family: "HafferXH";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 118.75% */
  letter-spacing: -0.32px;
`;

export const Headline3 = styled.h3`
  font-family: "HafferXH";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  letter-spacing: -0.24px;
`;

export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 480px;
  min-height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex: 1 0 0;
  > div {
    padding: ${theme.spacing[30]};
  }
`;

export const Minutiae = styled.span`
  font-family: "HafferXH";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const FAQIcon = styled.div`
  width: 24px;
  min-width: 24px;
  margin-right: 24px;
  height: 24px;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    path {
      fill: ${theme.colors.contentAccent}
    }
  }
  transition: transform 1s, opacity: 1s;
  &.opened {
    transform: rotate(360deg);
  }
`;

export const FAQBlock = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 490px;
  align-items: flex-start;
  padding-block: ${theme.spacing[24]};
  border-bottom: solid 1px ${theme.colors.bordersDecorative};
  p {
    height: 0;
    opacity: 0;
    display: block;
    transition: all 0.25s;
    transition-behavior: allow-discrete;
    margin: 0;
    &.opened {
      padding-top: ${theme.spacing[24]};
      height: max-content;
      opacity: 1;
    }
  }
`;
