import { transformRepeater } from "@src/components/page-provider/transformRepeater";
import data from "@src/assets/language/en/website2.website.json";

export const TRANSLATIONS = {
  ...data.directus.page_districts_2025,
  districtwide_cards: transformRepeater(data.directus.page_districts_2025.districtwide_cards),
  questions_faq: transformRepeater(data.directus.page_districts_2025.questions_faq),
  benefits_repeater: transformRepeater(data.directus.page_districts_2025.benefits_repeater),
  partners_repeater: transformRepeater(data.directus.page_districts_2025.partners_repeater),
};

export type TranslationType = typeof TRANSLATIONS;
